import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import JfaContributors from "../../components/contributors/JfaContributors";

const TeamPage = () => {
    return (
        <Layout pageTitle="JCI India Foundation | JFA Contributors">
            <NavOne />
            <PageHeader title="JFA Contributors" />
            <JfaContributors />
            <Footer />
        </Layout>
    );
};

export default TeamPage;

import React from "react";
import { Link } from "gatsby";

const ShortTitles = props => {
    return (
        <div class="jfa-contributor-cards jfa-contributor-cards">
            <Link to={props.blogContent.TitleLink}> <img class="img" src={props.blogContent.ImagePath} alt="avatar" /> </Link>
            <span class="jfa-contributor-cards_rect_1">
                <span class="jfa-contributor-shadow-1"></span>
            </span>
            <div class="jfa-contributor-title-bar">
                <Link to={props.blogContent.TitleLink}> <h1 class="jfa-contributor-title">{props.blogContent.Title}</h1> </Link>
            </div>
            <span class="jfa-contributor-cards_rect-2">
                <span class="jfa-contributor-shadow-2"></span>
            </span>
            <span class="jfa-contributor-cards_circle"></span>
            <ul class="jfa-contributor-cards_list">
                <div class="jfa-contributor-icon">
                    {props.blogContent.SocialLinks.map(item => (
                        <li>
                            <Link to={item.Link}> <i className={item.IconName}></i> </Link>
                        </li>
                    ))}
                </div>
                {/* <div class="jfa-contributor-icon1">
                    <li><a href="#" class="fa fa-google-plus"></a></li>
                    <li><a href="#" class="fa fa-linkedin"></a></li>
                </div> */}
            </ul>
        </div>
    );
};

export default ShortTitles;
